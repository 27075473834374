import React, { useState, useEffect  } from 'react';
import axios from 'axios';
import { useNavigate  } from 'react-router-dom';

const Login = () => {
  const [licenseKey, setLicenseKey] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [verificationResult, setVerificationResult] = useState('');
    const STORE_ID = 28229;
    const PRODUCT_ID = 268365;
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated()) {
          navigate('/');
        }
      }, [navigate]);

  const handleVerifyLicense = async () => {
    try {
      // Send Axios request to verify license key
      const response = await axios.post('https://api.lemonsqueezy.com/v1/licenses/activate', { license_key: licenseKey, instance_name:"react-app" });
        console.log(response.data);
        let isActivated = response.data.activated;
        let productID = response.data.meta.product_id;
        let storeID = response.data.meta.store_id;
        // let variant_id = response.data.meta.variant_id;

        if(isActivated){
            if(STORE_ID === storeID && PRODUCT_ID === productID ){
                setVerificationResult('License key verified successfully!');
                localStorage.setItem('token_license_key', licenseKey);
                navigate('/');
            }
            else {
              setVerificationResult('Invalid license key. It may be other product License Key');
          }
        }
        else {
            setVerificationResult('Invalid license key.');
        }
     
    } catch (error) {
      console.error('Error verifying license:', error);
      setVerificationResult('Invalid license key. Please enter valid license key.');
    }
  };
  const isAuthenticated = () => {
    // Implement your authentication check here
    return localStorage.getItem('token_license_key') !== null;
  };
  const handleDisableButton = () =>{
    if(licenseKey!==""){
        setIsDisabled(false);
    }
  }
  return (
    <div className="flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Verify License</h2>
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleVerifyLicense}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="licenseKey" className="sr-only">Your License Key</label>
              <input
                id="licenseKey"
                name="licenseKey"
                type="text"
                autoComplete="licenseKey"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"
                value={licenseKey}
                onChange={(e) => setLicenseKey(e.target.value)}
                onBlur={handleDisableButton}
              />
            </div>
          </div>
          <div>
            <button
              type="button"
              className="group relative w-full flex justify-center py-2 px-4 border border-black text-sm font-medium rounded-md text-white bg-black hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={isDisabled}  onClick={handleVerifyLicense}
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                <svg className="h-5 w-5 text-white group-hover:text-black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M4.293 6.707a1 1 0 010-1.414l5-5a1 1 0 011.414 0l5 5a1 1 0 01-1.414 1.414L11 3.414V16a1 1 0 11-2 0V3.414L5.707 6.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>
              </span>
              Verify License
            </button>
          </div>
        </form>
        {verificationResult && <p className="mt-2 text-center text-sm text-red-600">{verificationResult}</p>}
      </div>
    </div>
  );
}

export default Login;
