
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import { Home, Quiz } from './pages';

import Login from './pages/Login';
import Header from './pages/Header';


const isAuthenticated = () => {
  return localStorage.getItem('token_license_key') !== null; 
};

// ProtectedRoute component for rendering protected routes
const ProtectedRoute = ({ element, ...rest }) => {
  return isAuthenticated() ? element : <Navigate to="/verify" replace />;
};

function App() {
  return (
    <div>
      <Header/>
      <Router>
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/verify" exact element={<Login />} />
          <Route path="/questionnaire/:id" element={<ProtectedRoute element={<Quiz />} />} />
        </Routes>
       
      </Router>
    </div>
  );
}

export default App;
