import React from 'react'
import logo from './../logo.svg';

const Header = () => {
    return (
        <div className='sticky top-0 px-2 md:px-8 md:py-4 z-50 items-center justify-center flex mx-auto bg-background/30 backdrop-blur'>
            <div className='w-full max-w-[1440px]'>
                <ul className="flex justify-start w-full gap-10 h-20 items-center">
                    <li id="home" className="mr-auto"><a aria-label="Logo link" className="flex items-center gap-3 text-3xl font-bold" href="/">
                        <img className="w-[75px] md:w-[100px]" width="100%" height="100%" alt="logo" src={logo}/>
                    </a></li>
                    <li className="hidden md:block font-bold"><a className="block" href="/#how-it-works">How it works</a></li>
                    <li className="hidden md:block font-bold"><a className="block" href="/#resources">Resources</a></li>
                    <li className="hidden md:block font-bold"><a className="block" href="/#get-certified">Get Certified</a></li>
                    <li className="md:block font-bold">
                        <a className="block" target="_blank" rel="noreferrer" href="https://inagaraj.lemonsqueezy.com/buy/d93ba174-3b4f-4006-8527-786281b0be7b"> <button className="bg-black text-white hover:bg-white hover:text-black border-black border-1 transition ease-in-out delay-150 h-[50px] border-[1px] text-md px-8 font-bold rounded-2xl" type="button">Buy License</button></a>
                    </li>
                </ul>
            </div>
           

           
        </div>
    )
}

export default Header